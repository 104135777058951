<template>
  <default-layout>
    <v-container fluid tag="section">
      <lead-form />
    </v-container>
  </default-layout>
</template>

<script>
import DefaultLayout from '@/layouts/default';
import { mapState } from 'vuex';
const LeadForm = () => import('@/components/transaction-management/lead/form/lead-form.vue');

export default {
  name: 'project-add',
  components: {
    DefaultLayout,
    LeadForm,
  },
  data() {
    return {
      isLoading: false,
      showAlert: false,
      messageAlert: null,
    };
  },
  computed: {
    ...mapState({
      basePath: (state) => state.lead.basePath,
      leadUuid: (state) => state.lead.form.leadUuid,
    }),
  },
  async created() {
    await this.$store.dispatch('lead/form/restoreInitialState');
    this.$store.commit('lead/form/SET_FORM_TYPE', 'ADD');
    await this.$store.dispatch('lead/form/fetchBaseData');
    await this.$router.push(this.basePath + '/edit/' + this.leadUuid);
  },
};
</script>
